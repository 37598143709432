<template>
  <div class="body2">
    <span class="el-icon-error" @click="hide()"></span>
    <div class="swiper-container photo-detail">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="index">
          <div
            class="pic-wrap"
            @mouseenter="cur = index"
            @mouseleave="cur = -1"
          >
            <img class="pic" :src="item.image" />
            <!-- <div class="text" v-show="cur == index"> -->
            <div class="text">
              <p class="title">{{ item.title }}</p>
              <p class="desc">{{ item.describe }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
      <div class="swiper-button-prev"></div>
      <!--左箭头-->
      <div class="swiper-button-next"></div>
      <!--右箭头-->
    </div>
    <div class="swiper-container photo-detail-small">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in list" :key="index">
          <img :src="item.image" />
        </div>
      </div>
    </div>
    <div class="tool-bar">
      <a
        class="btn-edit"
        v-if="isMyworks"
        href="javascript:;"
        @click="edit"
      ></a>
      <a
        class="btn-delete"
        v-if="isMyworks"
        href="javascript:;"
        @click="del"
      ></a>
      <a
        class="btn-weixin"
        href="javascript:void(0)"
        @mouseenter="isShowQrcode = true"
        @mouseleave="isShowQrcode = false"
      >
        <div class="code-box" v-show="isShowQrcode">
          <img
            class="code"
            src="http://www.pai2345.com/static/web/res/images/code.png"
          />
          <div style="text-align: center;">
            微信“扫一扫”<br />即可将网页分享至朋友圈
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.js";
export default {
  props: {
    list: {
      type: Array
    },
    ids: {
      type: Object
    },
    groups: {
      type: Array
    }
  },
  inject: ["reload"],
  data() {
    return {
      cur: -1,
      swiper1: "",
      swiper2: "",
      // 作品页显示按钮不同
      isMyworks: this.$route.name == "myworks",
      isShowQrcode: false
    };
  },
  watch: {
    list: {
      immediate: true,
      handler(val) {
        this.list = val;
      }
    }
    // list() {
    //   return
    // }
    // editId(newVal, oldVal) {
    //   console.log(oldVal);
    //   this.editId = newVal;
    // }
  },
  methods: {
    initSwiper() {
      this.swiper2 = new Swiper(".photo-detail-small", {
        spaceBetween: 10,
        slidesPerView: 8,
        watchSlidesVisibility: true,
        watchSlidesProgress: true
      });
      this.swiper1 = new Swiper(".photo-detail", {
        // pagination: {
        //   el: ".swiper-pagination",
        //   type: "fraction"
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        spaceBetween: 10,
        thumbs: {
          swiper: this.swiper2
        },
        on: {
          imagesReady: function() {
            document
              .querySelectorAll(".photo-detail .pic")
              .forEach(function(item) {
                // let width = this.
                // console.log(item.clientWidth, item.clientHeight);
                // console.log(item.parentNode);
                item.parentNode.style.width = item.clientWidth + 16 + "px";
                item.parentNode.style.height = item.clientHeight + 16 + "px";
              });
            // $(".photo-detail .pic").each(function() {
            //   var picW = $(this).width(),
            //     picH = $(this).height();
            //   $(this)
            //     .parents(".pic-wrap")
            //     .width(picW);
            //   $(this)
            //     .parents(".pic-wrap")
            //     .height(picH);
            // });
            // this.swiper2.init();
          }
        },
        loop: true
      });
    },
    hide(callback) {
      this.$emit("display");
      if (typeof callback == "function") {
        callback();
      }
    },
    edit() {
      var link = '/contribute/'+this.ids.m_id+'/single/single';
      let theme = '';
      this.groups.forEach(element => {
        if(this.ids.group == element.gid){
          theme = element.theme;
        }
      });
      // 专题赛事
      if(theme != ''){
        link = `/theme/${theme}/${this.ids.m_id}`
      }else{
        if(this.ids.works_type == 2){
            link = '/contribute/'+this.ids.m_id+'/multi/multi';
        }
        if(this.ids.works_type == 3){
            link = '/contribute/'+this.ids.m_id+'/group/group';
        }
        if(this.ids.works_type == 4){
            link = '/contribute/'+this.ids.m_id+'/video/video';
        }
      }
      this.$router.push({
        path: link,
        query: {
          work_id: this.ids.work_id,
          m_id: this.ids.m_id,
          group: this.ids.group
        }
      });
    },
    del() {
      this.$confirm("您确定要删除该投稿的所有图片吗？ 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.axios.post("/match/delmyworks",{work_id:this.ids.work_id})
            .then(res => {
              // console.log(res);
              if (res.data.code == 200 && res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: "删除成功!"
                });
                this.hide(() => {
                  // console.log("执行刷新");
                  this.reload();
                });
              }
            })
            .catch(err => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  },
  mounted() {
    this.initSwiper();
  }
};
</script>

<style lang="scss">
.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 0;
}
.photo-detail .swiper-slide .text {
  background: rgba(0, 0, 0, 0.6);
}
.body2 {
  .el-icon-error {
    font-size: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    z-index: 2;
    cursor: pointer;
    &:hover {
      color: #ddd;
    }
  }
  .photo-detail-small .swiper-slide {
    cursor: default;
  }
}
</style>
